<template>
  <div>
    <a href="https://www.youtube.com/c/ProjectGranjaCaimito" target="_blank">
      <img id="youTube" src="@/assets/icons/youtube-icon.svg" /></a>
    <p><a href="https://www.youtube.com/c/ProjectGranjaCaimito" target="_blank">{{ $t('youTubeLink') }}</a></p>
  </div>
</template>

<style scoped>
DIV {
  display: grid;
  grid-template-columns: 100px auto;
  margin-top: 2em;
  margin-left: 2em;
  margin-bottom: 2em;
}

@media (max-width:440px) {
  DIV {
    margin-left: 1em;
  }
}

#youTube {
  height: 50px;
}
</style>
