<template>
  <div>
    <ul class="sidebar">
      <li>
        <router-link :to="{ name: 'concept' }"><span class="mx-4">{{ $t('conceptSidebar.overview')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'desertification' }"><span class="mx-4">{{ $t('conceptSidebar.desertification')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'regenerativeAgriculture' }"><span class="mx-4">{{
          $t('conceptSidebar.regenerativeAgriculture')
        }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animals' }"><span class="mx-4">{{ $t('conceptSidebar.animalStewardship')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'dehesa' }"><span class="mx-4">{{ $t('conceptSidebar.newDehesa')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'business' }"><span class="mx-4">{{ $t('conceptSidebar.isBusiness')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'priceSlider' }"><span class="mx-4">{{ $t('conceptSidebar.pricing')
        }}</span></router-link>
      </li>
    </ul>

    <p class="section-header">{{ $t('conceptSidebar.animals') }}</p>
    <ul class="sidebar">
      <li>
        <router-link :to="{ name: 'animalsOverview' }"><span class="mx-4">{{ $t('animalsSidebar.overview')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animalsPigs' }"><span class="mx-4">{{ $t('animalsSidebar.pigs')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animalsCattle' }"><span class="mx-4">{{ $t('animalsSidebar.cattle')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animalsSheep' }"><span class="mx-4">{{ $t('animalsSidebar.sheep')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animalsDogs' }"><span class="mx-4">{{ $t('animalsSidebar.dogs')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'animalsHorses' }"><span class="mx-4">{{ $t('animalsSidebar.horses')
        }}</span></router-link>
      </li>
    </ul>

    <p class="section-header">{{ $t('conceptSidebar.plants') }}</p>
    <ul class="sidebar">
      <li>
        <router-link :to="{ name: 'plants' }"><span class="mx-4">{{
          $t('plantsSidebar.overview')
        }}</span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'zones' }"><span class="mx-4">{{
          $t('plantsSidebar.zones')
        }}</span></router-link>
      </li>
    </ul>
  </div>
</template>
