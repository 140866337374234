<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Is This a Business?</h1>
      <p>
        The short answer is: it might become one but it isn't one at the moment.
      </p>
      <p>
        Since we bought the land in late 2017 this has been a money sink. A significant amount of money was paid for the
        land, there are fulltime employees, animals have been purchased and fed with purchased feed as the land is only
        able to provide enough for a fraction of the year due to a drought of about 6 months every year. It is not that
        the land is not able to carry animals and produce. It is that the land is damaged and we are repairing it.
      </p>
      <p>
        The means to finance the ongoing operation and investment comes from work in the IT sector.
        We did eat from the land though. So far we've turned some pigs and even bovines into food for ourselves.
        Currently, our main goal is reach <em>net zero</em> on the balance sheet.
      </p>

      <h2>Future Products</h2>
      <p>
        In order to reach the goal of <em>net zero</em> we are looking into ways to offer some products without
        contradicting what this project is about. It is mainly and foremost a project to create abundance in soil, flora
        and fauna and fungi.
      </p>
      <p>
        That means whatever product we are going to have it is going to be special and scarce - all dependent
        on the development of the land where it comes from.
      </p>

      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "Business",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
